




















import { VTextField } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import { AddAnswerDocument } from '@/generated/operations';

export default {
  name: 'AddAnswerForm',
  components: { AbstractModalForm },
  props: {
    questionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      MUTATION: AddAnswerDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'name' },
                props: { label: 'treść' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                initialData: () => false,
                model: { out: 'points' },
                props: { type: 'number', label: 'punktów za odpowiedź', max: 10, min: 0 },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
};
