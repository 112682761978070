<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <GraphqlServerSideDataGrid
          title="Odpowiedzi do sprawdzenia"
          :headers="headers"
          :query="query"
          :update="(data) => data.exams.list"
          :addEnabled="false"
          @editItem="editItem"
          @displayItem="displayItem"
        >
          <template v-slot:[`item.level`]="{ item }">
            {{ item.level.name }}
          </template>
          <template v-slot:[`item.id`]="{ item }">
            {{ item.question }}
          </template>
        </GraphqlServerSideDataGrid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GraphqlServerSideDataGrid from '@/components/Common/GraphqlServerSideDataGrid.vue';
import { ListOpenUserAnswersDocument } from '@/generated/operations';

export default {
  name: 'ExamCheckerPage',
  components: {
    GraphqlServerSideDataGrid,
  },
  data() {
    return {
      query: ListOpenUserAnswersDocument,
      headers: [
        { text: 'ID', sortable: true, value: 'id' },
        { text: 'Odpowiedź', sortable: false, value: 'userAnswerContent' },
        { text: 'Punktów', sortable: false, value: 'userPoints' },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.$router.push({ name: 'exams/edit', params: { id: item.id } });
    },
    displayItem(item) {
      this.$router.push({ name: 'exams/display', params: { id: item.id } });
    },
  },
};
</script>
