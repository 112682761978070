<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card v-if="exam" color="grey lighten-3">
          <v-card-title>{{ exam.name }}</v-card-title>
          <v-card-subtitle>{{ exam.level.name }}</v-card-subtitle>
          <v-card-text>
            <v-alert v-if="exam.activeVersion.locked" color="orange" type="warning">
              Aktualna wersja tego egzaminu jest już użyta w testach. Utwórz jej kopię aby móc edytowac pytania etc.
            </v-alert>
            <AddExamVersionButton :exam-id="exam.id" v-if="exam.activeVersion.locked" />
          </v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title> Aktywny</v-list-item-title>
              <v-list-item-subtitle>{{ exam.active }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title> Rodzaj</v-list-item-title>
              <v-list-item-subtitle>{{ exam.activeVersion.__typename | mapType }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title> Odpowiedniki</v-list-item-title>
              <v-list-item v-for="relatedExam in exam.group.exams" :key="relatedExam.id">
                <v-btn
                  :disabled="exam.id === relatedExam.id"
                  x-small
                  @click="$router.push({ name: 'exams/display', params: { id: relatedExam.id } })"
                >
                  {{ relatedExam.name }}
                </v-btn>
              </v-list-item>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line v-if="exam.activeVersion.maxTime">
            <v-list-item-content>
              <v-list-item-title> Czas na ukończenie</v-list-item-title>
              <v-list-item-subtitle>{{ exam.activeVersion.maxTime }} minut</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title> Ilość punktów we wszystkich pytaniach</v-list-item-title>
              <v-list-item-subtitle>{{ exam.activeVersion.maxPoints }} pkt.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title> Próg zaliczenia</v-list-item-title>
              <v-list-item-subtitle> {{ exam.activeVersion.pointThreshold }} pkt. </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title> Waga punktów egzaminu </v-list-item-title>
              <v-list-item-subtitle>
                {{ exam.activeVersion.weight }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title> Próg zaliczenia na wyższy stopień</v-list-item-title>
              <v-list-item-subtitle v-if="exam.activeVersion.pointThresholdHigherRank">
                {{ exam.activeVersion.pointThresholdHigherRank }} % ({{
                  (exam.activeVersion.maxPoints * exam.activeVersion.pointThresholdHigherRank) / 100
                }}
                pkt)
              </v-list-item-subtitle>
              <v-list-item-subtitle v-else>
                BRAK ( jeżeli kursant zaliczy egzamin, zaliczy go również na wyższy stopień )
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <AddQuestionForm
              v-if="exam.activeVersion.acl_edit && exam.activeVersion.__typename === 'OnlineExamVersion'"
              :exam-version-id="exam.activeVersion.id"
            />
          </v-card-actions>
          <v-card-text>
            <v-card class="mb-2" v-for="question in exam.activeVersion.questions" :key="question.id">
              <v-card-title>
                <v-chip small class="mr-1">{{ question.__typename | mapQuestionType }}</v-chip>
                {{ question.name }}
              </v-card-title>
              <v-card-actions>
                <EditQuestionForm :question="question" :key="question.name" class="mr-1" v-if="question.acl_edit" />
                <UploadQuestionFileForm v-if="question.acl_edit" :question-id="question.id" />
                <RemoveQuestionButton :question-id="question.id" v-if="question.acl_delete" />
              </v-card-actions>
              <v-card-subtitle> (max. {{ question.maxPoints }} pkt)</v-card-subtitle>
              <v-card-text>
                <ShowFile :file="question.file" />
                <AddAnswerForm
                  v-if="question.__typename == 'Question' && question.acl_edit"
                  :question-id="question.id"
                />
                <v-simple-table v-if="question.__typename == 'Question'">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Treść</th>
                        <th class="text-left">Punktów za odpowiedź</th>
                        <th class="text-left"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="answer in question.answers" :key="answer.id">
                        <td>{{ answer.name }}</td>
                        <td>{{ answer.points }}</td>
                        <td class="text-right">
                          <EditAnswerForm :answer="answer" v-if="answer.acl_edit" class="mr-1" />
                          <RemoveAnswerButton :answer-id="answer.id" v-if="answer.acl_delete" />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ExamDetailsQueryDocument } from '@/generated/operations';
import AddQuestionForm from '@/pages/exam/components/AddQuestionForm.vue';
import AddAnswerForm from '@/pages/exam/components/AddAnswerForm.vue';
import EditQuestionForm from '@/pages/exam/components/EditQuestionForm.vue';
import AddExamVersionButton from '@/pages/exam/components/AddExamVersionButton.vue';
import EditAnswerForm from '@/pages/exam/components/EditAnswerForm.vue';
import RemoveAnswerButton from '@/pages/exam/components/RemoveAnswerButton.vue';
import RemoveQuestionButton from '@/pages/exam/components/RemoveQuestionButton.vue';
import UploadQuestionFileForm from '@/pages/exam/components/UploadQuestionFileForm.vue';
import ShowFile from '@/pages/exam/ShowFile.vue';
import { mapType } from '@/helpers/filters';

export default {
  name: 'ExamDetailsPage',
  components: {
    ShowFile,
    UploadQuestionFileForm,
    RemoveQuestionButton,
    RemoveAnswerButton,
    EditAnswerForm,
    AddExamVersionButton,
    EditQuestionForm,
    AddAnswerForm,
    AddQuestionForm,
  },
  filters: {
    mapType,
    mapQuestionType(type) {
      if (type === 'Question') {
        return 'pytanie zamknięte';
      }
      return 'pytanie otwarte';
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {},
  apollo: {
    exam: {
      query: ExamDetailsQueryDocument,
      variables() {
        return {
          examId: this.id,
        };
      },
      update: (data) => data.exam.items[0],
    },
  },
};
</script>
