





















import { VFileInput } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import { UploadQuestionFileDocument } from '@/generated/operations';

export default {
  name: 'UploadQuestionFileForm',
  components: { AbstractModalForm },
  props: {
    questionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      MUTATION: UploadQuestionFileDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VFileInput,
                model: { out: 'file' },
                props: { label: 'plik' },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
};
