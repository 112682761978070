
















import { removeQuestionMutation } from '@/generated/operations';
import ConfirmModal from '@/components/Common/ConfirmModal.vue';

export default {
  name: 'RemoveQuestionButton',
  props: {
    questionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errors: [],
    };
  },
  components: { ConfirmModal },
  methods: {
    async mutate() {
      this.loading = true;
      const { errors } = await removeQuestionMutation(this, {
        variables: {
          input: { entityId: this.questionId },
        },
      });
      this.errors = errors;
      this.loading = false;
    },
  },
};
