<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <GraphqlServerSideDataGrid
          title="Egzaminy"
          :headers="headers"
          :query="query"
          :update="(data) => data.exams.list"
          :addEnabled="false"
          @editItem="editItem"
          @displayItem="displayItem"
        >
          <!--          <template v-slot:[`title-buttons-left`]>-->
          <!--            <AclScope v-slot="{ currentUser }">-->
          <!--              <AddUserForm v-if="currentUser.acl_create_exams" />-->
          <!--            </AclScope>-->
          <!--          </template>-->
          <template v-slot:[`item.level`]="{ item }">
            {{ item.level.name }}
          </template>
          <template v-slot:[`item.type`]="{ item }">
            {{ item.activeVersion.__typename | mapType }}
          </template>
          <!--          <template v-slot:[`item.action`]="{ item }">-->
          <!--            {{ item }}-->
          <!--          </template>-->
        </GraphqlServerSideDataGrid>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GraphqlServerSideDataGrid from '@/components/Common/GraphqlServerSideDataGrid.vue';
import { ListExamsQueryDocument } from '@/generated/operations';
import { mapType } from '@/helpers/filters';

export default {
  name: 'ExamsListPage',
  components: {
    GraphqlServerSideDataGrid,
  },
  data() {
    return {
      query: ListExamsQueryDocument,
      headers: [
        // { text: 'ID', sortable: true, value: 'id' },
        { text: 'Nazwa', sortable: false, value: 'name' },
        { text: 'Typ', sortable: false, value: 'type' },
        { text: 'Poziom', sortable: false, value: 'level' },
        { text: 'Aktywny', sortable: false, value: 'active' },
        { text: 'Akcje', sortable: false, value: 'action' },
      ],
    };
  },
  filters: {
    mapType,
  },
  methods: {
    editItem(item) {
      this.$router.push({ name: 'exams/edit', params: { id: item.id } });
    },
    displayItem(item) {
      this.$router.push({ name: 'exams/display', params: { id: item.id } });
    },
  },
};
</script>
