






















import { VTextField } from 'vuetify/lib';
import { isNumberValidator, isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import { EditAnswerDocument } from '@/generated/operations';

export default {
  name: 'EditAnswerForm',
  components: { AbstractModalForm },
  props: {
    answer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      MUTATION: EditAnswerDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'name' },
                props: { label: 'Treść' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'points' },
                props: { type: 'number', label: 'Punkty' },
                rules: [isNumberValidator],
              },
            ],
          },
        ],
      },
    };
  },
};
