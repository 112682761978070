
















import { removeAnswerMutation } from '@/generated/operations';
import ConfirmModal from '@/components/Common/ConfirmModal.vue';

export default {
  name: 'RemoveAnswerButton',
  props: {
    answerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      errors: null,
    };
  },
  components: { ConfirmModal },
  methods: {
    async mutate() {
      this.loading = true;
      this.errors = null;
      const { errors } = await removeAnswerMutation(this, {
        variables: {
          input: { entityId: this.answerId },
        },
      });
      this.errors = errors;
      this.loading = false;
    },
  },
};
