// eslint-disable-next-line import/prefer-default-export
export const mapType = (value) => {
  switch (value) {
    case 'OnlineExamVersion':
      return 'Online';
    case 'PracticalExamVersion':
      return 'Praktyczny';
    case 'TheoreticalExamVersion':
      return 'Teoretyczny';
    default:
      return value;
  }
};
