



















import { VTextField, VSelect } from 'vuetify/lib';
import { isRequiredValidator } from '@/helpers/validators';
import AbstractModalForm from '@/components/Form/AbstractModalForm.vue';
import { AddQuestionDocument } from '@/generated/operations';

export default {
  name: 'AddQuestionForm',
  props: {
    examVersionId: {
      type: String,
      required: true,
    },
  },
  components: { AbstractModalForm },
  data() {
    return {
      showModal: false,
      MUTATION: AddQuestionDocument,
      formSpecification: {
        fields: [
          {
            columns: [
              {
                cols: { md: 12 },
                type: VTextField,
                model: { out: 'name' },
                props: { label: 'treść' },
                rules: [isRequiredValidator],
              },
              {
                cols: { md: 12 },
                type: VSelect,
                model: { out: 'type' },
                props: {
                  'label': 'typ',
                  'prepend-icon': 'mdi-identifier',
                  'items': [
                    {
                      value: 'OPEN',
                      text: 'Pytanie otwarte ( opisowe )',
                    },
                    {
                      value: 'CLOSED',
                      text: 'Pytanie zamknięte ( wyboru )',
                    },
                  ],
                },
                rules: [isRequiredValidator],
              },
            ],
          },
        ],
      },
    };
  },
};
