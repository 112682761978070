













import { addExamVersionMutation } from '@/generated/operations';
import ConfirmModal from '@/components/Common/ConfirmModal.vue';

export default {
  name: 'AddExamVersionButton',
  props: {
    examId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  components: { ConfirmModal },
  methods: {
    async mutate() {
      this.loading = true;
      const { errors } = await addExamVersionMutation(this, {
        variables: {
          input: { examId: this.examId },
        },
      });
      if (errors) {
        // eslint-disable-next-line no-alert
        alert(errors);
      }
      this.loading = false;
    },
  },
};
